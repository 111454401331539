import { encrypt, decrypt } from '@/config/crypto';
import nookies, { parseCookies, setCookie, destroyCookie } from 'nookies';

export class Cookies {
    private context: any = null;
    private environment: string =
        typeof window === 'undefined' ? 'server' : 'client';
    constructor(context: any = null) {
        this.context = context;
    }
    private serverSet(key: string, value: string, options?: any) {
        nookies.set(this.context, key, value, options);
    }
    private clientSet(key: string, value: string, options?: any) {
        setCookie(null, key, value, options);
    }
    private serverGet(key: string) {
        const cookie = nookies.get(this.context);
        return cookie[key];
    }
    private clientGet(key: string) {
        return parseCookies()[key];
    }
    public set(key: string, value: string, options?: any) {
        if (this.environment === 'server')
            return this.serverSet(key, value, options);
        return this.clientSet(key, value, options);
    }
    public get(key: string) {
        if (this.environment === 'server') return this.serverGet(key);
        return this.clientGet(key);
    }
    get accessToken() {
        try {
            const access_token = this.get('access_token');
            return decrypt(access_token);
        } catch (err) {
            return '';
        }
    }
    set accessToken(value: string) {
        try {
            const access_token = encrypt(value);
            this.set('access_token', access_token, { path: '/' });
        } catch (err) {}
    }
    get refreshToken() {
        try {
            const refresh_token = this.get('refresh_token');
            return decrypt(refresh_token);
        } catch (err) {
            return '';
        }
    }
    set refreshToken(value: string) {
        try {
            const refresh_token = encrypt(value);
            this.set('refresh_token', refresh_token, { path: '/' });
        } catch (err) {}
    }
}
