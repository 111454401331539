import React from 'react';
import NextHead from 'next/head';
import { siteConfig } from '@/config/site';
import { Diag } from '@/stores/diag';
export const Head = ({ title }: { title: string }) => {
    return (
        <>
            <NextHead>
                <title>{title}</title>
                <meta key="title" content={title} property="og:title" />
                <meta
                    content={siteConfig.description}
                    property="og:description"
                />
                <meta content={siteConfig.description} name="description" />
                <meta
                    key="viewport"
                    content="viewport-fit=cover, width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
                    name="viewport"
                />
                <link href="/favicon.ico" rel="icon" />
            </NextHead>
            <Diag />
        </>
    );
};
