import React, { FC, useState, useEffect } from 'react';
import { VisuallyHidden } from '@react-aria/visually-hidden';
import {
    Button,
    Spinner,
    SwitchProps,
    Tooltip,
    useSwitch,
} from '@nextui-org/react';
import { useTheme } from 'next-themes';
import clsx from 'clsx';

export interface ThemeSwitchProps {
    className?: string;
    classNames?: SwitchProps['classNames'];
}

export const ThemeSwitch: FC<ThemeSwitchProps> = ({
    className,
    classNames,
}) => {
    const [isMounted, setIsMounted] = useState(false);

    const { theme, setTheme } = useTheme();

    const onChange = () => {
        theme === 'light' ? setTheme('dark') : setTheme('light');
    };

    const {
        Component,
        slots,
        isSelected,
        getBaseProps,
        getInputProps,
        getWrapperProps,
    } = useSwitch({
        isSelected: theme === 'light',
        onChange,
    });

    useEffect(() => {
        setIsMounted(true);
    }, [isMounted]);

    // Prevent Hydration Mismatch
    if (!isMounted) return <div className="w-6 h-6" />;

    return (
        <Component
            {...getBaseProps({
                className: clsx(
                    'px-px transition-opacity hover:opacity-80 cursor-pointer',
                    className,
                    classNames?.base
                ),
            })}
        >
            <VisuallyHidden>
                <input {...getInputProps()} />
            </VisuallyHidden>
            <div
                {...getWrapperProps()}
                className={slots.wrapper({
                    class: clsx(
                        [
                            'w-auto h-auto',
                            'bg-transparent',
                            'rounded-lg',
                            'flex items-center justify-center',
                            'group-data-[selected=true]:bg-transparent',
                            '!text-default-500',
                            'pt-px',
                            'px-0',
                            'mx-0',
                        ],
                        classNames?.wrapper
                    ),
                })}
            >
                {isSelected ? (
                    <i className="fa-regular fa-lightbulb"></i>
                ) : (
                    <i className="fa-solid fa-lightbulb"></i>
                )}
            </div>
        </Component>
    );
};

const ThemeSwitchButtonStyles = {
    width: '40px',
    maxWidth: '40px',
    minWidth: '40px',
};
export const ThemeSwitchButton: FC<ThemeSwitchProps> = ({
    className,
    classNames,
}) => {
    const [isMounted, setIsMounted] = useState(false);

    const { theme, setTheme } = useTheme();

    const onChange = () => {
        theme === 'light' ? setTheme('dark') : setTheme('light');
    };

    const {
        Component,
        slots,
        isSelected,
        getBaseProps,
        getInputProps,
        getWrapperProps,
    } = useSwitch({
        isSelected: theme === 'light',
        onChange,
    });

    useEffect(() => {
        setTimeout(() => {
            setIsMounted(true);
        }, 500);
    }, [isMounted]);

    // Prevent Hydration Mismatch
    if (!isMounted)
        return (
            <Button
                isIconOnly
                color="primary"
                style={ThemeSwitchButtonStyles}
                variant="light"
            >
                <Spinner size="sm" />
            </Button>
        );

    return (
        <>
            <Component
                {...getBaseProps({
                    className: clsx(
                        'px-px transition-opacity hover:opacity-80 cursor-pointer',
                        className,
                        classNames?.base
                    ),
                })}
            >
                <VisuallyHidden>
                    <input {...getInputProps()} />
                </VisuallyHidden>
                <Tooltip content="Toggle Theme" placement="top">
                    <Button
                        isIconOnly
                        onClick={() => onChange()}
                        color="primary"
                        style={ThemeSwitchButtonStyles}
                        variant="light"
                    >
                        {isSelected ? (
                            <i className="fa-regular fa-lightbulb"></i>
                        ) : (
                            <i className="fa-solid fa-lightbulb"></i>
                        )}
                    </Button>
                </Tooltip>
            </Component>
        </>
    );
};
