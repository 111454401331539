import { signal, computed } from '@preact/signals-react';
import { request, navigate } from '@/config/network';
import { diag } from '@/stores/diag';
import { encrypt, decrypt } from '@/config/crypto';
import { NextApiRequest, NextApiResponse } from 'next';
import { Cookies } from '@/config/cookies';
const logindata = signal({ email: '', password: '' });
const loginupdate = (key: string) => (e: any) =>
    (logindata.value = { ...logindata.value, [key]: e.target.value });

const loginsubmit = {
    reset: (close: any) => {
        request()
            .post('auth/reset-password', logindata.value)
            .then((res: any) => {
                let { password_reset_token } = res.data;
                password_reset_token = encodeURIComponent(password_reset_token);
                diag.open(
                    'Reset password email is not implemented yet',
                    "You'll be redirected to the reset page.",
                    {
                        timeout: 5000,
                        intent: 'primary',
                        dismiss: 'Continue',
                        dissmissTimer: true,
                        icon: 'fa-solid fa-share-from-square',
                        onDismiss: () => {
                            navigate(
                                `/auth/reset-password/${password_reset_token}`
                            );
                        },
                    }
                );
                close();
            })
            .catch((err: any) => {
                const message =
                    err?.response?.data?.message ||
                    err?.message ||
                    'Unknown error';
                diag.open('Reset failed', message, {
                    intent: 'danger',
                    icon: 'fa-solid fa-user-secret',
                });
            });
    },
    login: () => {
        request()
            .post('auth/signin', logindata.value)
            .then((res: any) => {
                const { access_token, refresh_token } = res.data;
                const cookies = new Cookies();
                cookies.accessToken = access_token;
                cookies.refreshToken = refresh_token;
                navigate('/');
            })
            .catch((err: any) => {
                const message =
                    err?.response?.data?.message ||
                    err?.message ||
                    'Unknown error';
                diag.open('Authentication failed', message, {
                    intent: 'danger',
                    icon: 'fa-solid fa-user-secret',
                });
            });
    },
};
const loginevents = {
    enter: (e: any) => {
        if (e.key === 'Enter') loginsubmit.login();
    },
};

const loginvalid = computed(() => {
    const { email, password } = logindata.value;
    return email.length > 0 && password.length > 0;
});

export const logout = () => {
    const cookies = new Cookies();
    cookies.accessToken = '';
    cookies.refreshToken = '';
    navigate('/auth/signin');
};

export const check = async (context: any, scope: string = 'undefined') => {
    try {
        await request(context).get('auth/check');
        /*
        if (scope === 'auth') {
            context.res.setHeader("location", "/");
            context.res.statusCode = 302;
            context.res.end();
        }
        */
    } catch (err) {
        try {
            const res = await request(context)
                .post('auth/refresh', {
                    refresh_token: new Cookies(context).refreshToken,
                })
                .then((res: any) => res.data);
            if (!res.access_token) throw new Error('Invalid refresh token');
            else {
                const cookies = new Cookies(context);
                cookies.accessToken = res.access_token;
                cookies.refreshToken = res.refresh_token;
            }
            return { data: { message: 'OK' } };
        } catch (err) {
            if (scope === 'auth') return { data: { message: 'OK' } };
            context.res.setHeader('location', '/auth/signin');
            context.res.statusCode = 302;
            context.res.end();
        }
    }
};

export const login = {
    data: logindata,
    update: loginupdate,
    submit: loginsubmit,
    events: loginevents,
    valid: loginvalid,
};

const resetdata = signal({ password: '', password_confirmation: '' });
const resetupdate = (key: string) => (e: any) =>
    (resetdata.value = { ...resetdata.value, [key]: e.target.value });
const resetsubmit = {
    reset: (token: string) => {
        request()
            .post('auth/change-password', {
                password: resetdata.value.password,
                password_reset_token: token,
            })
            .then((res: any) => {
                diag.open('Password reset', 'Your password has been reset.', {
                    intent: 'primary',
                    icon: 'fa-solid fa-user-secret',
                    dismiss: 'Continue',
                    dissmissTimer: true,
                    onDismiss: () => {
                        navigate(`/auth/signin`);
                    },
                });
            })
            .catch((err: any) => {
                const message =
                    err?.response?.data?.message ||
                    err?.message ||
                    'Unknown error';
                diag.open('Reset failed', message, {
                    intent: 'danger',
                    icon: 'fa-solid fa-user-secret',
                });
            });
    },
};
const resetvalid = computed(() => {
    const { password, password_confirmation } = resetdata.value;
    return (
        password.length > 0 &&
        password_confirmation.length > 0 &&
        password === password_confirmation
    );
});
const resetevents = {
    enter: (e: any, token: string) => {
        if (e.key === 'Enter') resetsubmit.reset(token);
    },
};

export const reset = {
    data: resetdata,
    update: resetupdate,
    submit: resetsubmit,
    valid: resetvalid,
    events: resetevents,
};

export default {
    login,
    reset,
};
