import {
    signal,
    computed,
    effect,
    useSignalEffect,
    useSignal,
    Signal,
    untracked,
} from '@preact/signals-react';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Input,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Divider,
    Button,
    Tooltip,
} from '@nextui-org/react';
import React from 'react';

export interface IDiag {
    open: boolean;
    title: string;
    content: string;
    timeout: number;
    intent:
        | 'primary'
        | 'default'
        | 'secondary'
        | 'success'
        | 'warning'
        | 'danger';
    icon: string | undefined;
    onDismiss: () => void;
    dismiss: string;
    actions: any[];
    actionsContainer: any;
    dissmissTimer: boolean;
}

export interface IDiagOptions {
    timeout?: number;
    intent?:
        | 'primary'
        | 'default'
        | 'secondary'
        | 'success'
        | 'warning'
        | 'danger';
    icon?: string | undefined;
    onDismiss?: () => void;
    dismiss?: string;
    actions?: any[];
    actionsContainer?: any;
    dissmissTimer?: boolean;
}

export interface IOpenDiag {
    (title: string, content: string, options?: IDiagOptions): void;
}

const dialog = signal({
    open: false,
    title: '',
    content: '',
    timeout: 3000,
    intent: 'primary',
    icon: undefined,
    onDismiss: () => {},
    dismiss: 'Dismiss',
    dissmissTimer: false,
    actions: [],
    actionsContainer: {},
}) as Signal<IDiag>;
const openDialog = (
    title: string,
    content: string,
    options = {
        timeout: 3000,
        intent: 'primary',
        icon: undefined,
        dismiss: 'Dismiss',
        dissmissTimer: false,
        onDismiss: () => {},
        actions: [],
        actionsContainer: {},
    } as IDiagOptions
) =>
    (dialog.value = {
        ...dialog.value,
        open: true,
        title,
        content,
        ...options,
    });
let timeouts = {} as { open?: NodeJS.Timeout };
let timer = signal(0);
const timerInterval = {
    interval: undefined as NodeJS.Timeout | undefined,
    clear: () => {
        if (timerInterval.interval) clearInterval(timerInterval.interval);
    },
    set: () =>
        setInterval(() => {
            let v = timer.value - 1;
            timer.value = v > 0 ? v : 0;
        }, 1000),
};
const dismissed = signal(false);
const safeDismiss = () => {
    setTimeout(() => {
        untracked(() => {
            !dismissed.value &&
                dialog.value.onDismiss &&
                dialog.value.onDismiss();
            dismissed.value = true;
        });
    }, 100);
    setTimeout(() => {
        dialog.value = { ...dialog.value, open: false };
    }, 200);
};

effect(() => {
    if (dialog.value.open && dialog.value.timeout) {
        timerInterval.clear();
        timer.value = parseInt((dialog.value.timeout / 1000).toFixed(0));
        timerInterval.set();
        timeouts.open = setTimeout(() => {
            safeDismiss();
        }, dialog.value.timeout);
    } else {
        if (timeouts.open) clearTimeout(timeouts.open);
    }
});

export const diag = {
    open: openDialog,
};

export const Diag = () => {
    const { title, content, intent, icon, actions, actionsContainer } =
        dialog.value;
    const onOpenChange = () => {
        dialog.value = { ...dialog.value, open: !dialog.value.open };
    };
    const onClose = () => {
        dialog.value = { ...dialog.value, open: false };
    };
    const onOpen = () => {
        dialog.value = { ...dialog.value, open: true };
    };
    const isOpen = dialog.value.open;
    return (
        <>
            <Modal
                backdrop="blur"
                isOpen={isOpen}
                onOpenChange={onOpenChange}
                hideCloseButton
                placement="center"
            >
                <ModalContent>
                    {(onClose) => (
                        <>
                            <ModalHeader className="flex flex-col gap-1 w-full justify-center items-center">
                                {title}
                            </ModalHeader>
                            <ModalBody className="flex flex-row gap-1 w-full justify-center items-center">
                                {icon && <i className={icon}></i>}
                                <p>{content}</p>
                            </ModalBody>
                            <ModalFooter
                                className="flex flex-row gap-1 w-full justify-center items-center"
                                {...actionsContainer}
                            >
                                {actions.length > 0 ? (
                                    actions.map(
                                        (action: any, index: number) => {
                                            return (
                                                <div
                                                    key={`diag-action-${index}`}
                                                >
                                                    <Button
                                                        {...action}
                                                        onPress={() => {
                                                            if (
                                                                typeof action.onPress ===
                                                                'function'
                                                            )
                                                                action.onPress({
                                                                    close: () =>
                                                                        onClose(),
                                                                });
                                                        }}
                                                    />
                                                </div>
                                            );
                                        }
                                    )
                                ) : (
                                    <Button
                                        color={intent}
                                        onPress={() => {
                                            onClose();
                                            safeDismiss();
                                        }}
                                    >
                                        {dialog.value.dissmissTimer &&
                                            timer.value}{' '}
                                        {dialog.value.dismiss}
                                    </Button>
                                )}
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
};

export default {
    Diag,
    diag,
};
