import CryptoJS from 'crypto-js';

const browserSig = '123123123';

const encrypt = (text: string) => {
    return CryptoJS.AES.encrypt(text, browserSig).toString();
};
const decrypt = (text: string) => {
    return CryptoJS.AES.decrypt(text, browserSig).toString(CryptoJS.enc.Utf8);
};

export { encrypt, decrypt };
