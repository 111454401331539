import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React from 'react';
import {
    signal,
    effect,
    useSignal,
    useSignalEffect,
} from '@preact/signals-react';
import { Cookies } from '@/config/cookies';
const { accessToken, refreshToken } = new Cookies();
const baseUrl =
    process.env.NEXT_PUBLIC_SKIP_SSL !== 'true'
        ? `https://api.${process.env.NEXT_PUBLIC_BASE_URL}/`
        : `http://${process.env.NEXT_PUBLIC_BASE_URL}/`;
const getUrl = (url: string) => `${baseUrl}${url}`;
import { logout } from '@/stores/auth';

export const request = (context: any = null) => ({
    get: (url: string, config?: AxiosRequestConfig) => {
        return axios.get(getUrl(url), {
            ...config,
            headers: {
                Authorization: 'Bearer ' + new Cookies(context).accessToken,
                ...config?.headers,
            },
        });
    },
    post: (url: string, data?: any, config?: AxiosRequestConfig) => {
        return axios.post(getUrl(url), data, {
            ...config,
            headers: {
                Authorization: 'Bearer ' + new Cookies(context).accessToken,
                ...config?.headers,
            },
        });
    },
    put: (url: string, data?: any, config?: AxiosRequestConfig) => {
        return axios.put(getUrl(url), data, {
            ...config,
            headers: {
                Authorization: 'Bearer ' + new Cookies(context).accessToken,
                ...config?.headers,
            },
        });
    },
    patch: (url: string, data?: any, config?: AxiosRequestConfig) => {
        return axios.patch(getUrl(url), data, {
            ...config,
            headers: {
                Authorization: 'Bearer ' + new Cookies(context).accessToken,
                ...config?.headers,
            },
        });
    },
    delete: (url: string, config?: AxiosRequestConfig) => {
        return axios.delete(getUrl(url), {
            ...config,
            headers: {
                Authorization: 'Bearer ' + new Cookies(context).accessToken,
                ...config?.headers,
            },
        });
    },
});

export const navigate = (path: string) => {
    window.open(path, '_self');
};

export interface IFetcherInterface {
    endpoint: string;
    data?: any;
    params?: any;
    defaultData?: any;
}

const useReactFetch = (
    params: IFetcherInterface,
    fetcher: (params: IFetcherInterface) => Promise<any>
) => {
    const [data, setData] = React.useState(params?.defaultData);
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(null);
    const { params: fetcherParams, endpoint, defaultData } = params;
    React.useEffect(() => {
        if (!endpoint) return;
        if (loading) return;
        setLoading(true);
        fetcher(params)
            .then((res) => {
                setError(null);
                setData(res);
                setLoading(false);
            })
            .catch((err) => {
                setData(defaultData);
                setError(err);
                setLoading(false);
            });
    }, [fetcherParams, endpoint]);
    return { data, loading, error };
};

const useSignalFetch = (
    params: IFetcherInterface,
    fetcher: (params: IFetcherInterface) => Promise<any>
) => {
    const data = useSignal(params?.defaultData);
    const loading = useSignal(false);
    const error = useSignal(null);
    useSignalEffect(() => {
        if (!params?.endpoint) return;
        if (loading.value) return;
        loading.value = true;
        fetcher(params)
            .then((res) => {
                error.value = null;
                loading.value = false;
            })
            .catch((err) => {
                data.value = params?.defaultData;
                error.value = err;
                loading.value = false;
            });
    });
    return { data, loading, error };
};

const useSignalGlobalFetch = (
    params: IFetcherInterface,
    fetcher: (params: IFetcherInterface) => Promise<any>
) => {
    const data = signal(params?.defaultData);
    const loading = signal(false);
    const error = signal(null);
    effect(() => {
        if (!params?.endpoint) return;
        if (loading.value) return;
        loading.value = true;
        fetcher(params)
            .then((res) => {
                error.value = null;
                loading.value = false;
            })
            .catch((err) => {
                data.value = params?.defaultData;
                error.value = err;
                loading.value = false;
            });
    });
    return { data, loading, error };
};

export { useReactFetch, useSignalFetch, useSignalGlobalFetch };
